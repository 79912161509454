import React from 'react';
import './game.css';

const Game = () => (
    <section className="game section__padding scale-up-center">
    <h2>MAKE MEANINGFUL CONNECTIONS</h2>
    <p>with like-minded businesses and athletes who share your values</p>
  </section>

);

export default Game;