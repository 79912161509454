import React from 'react';
import { Link } from 'react-router-dom';
import logo from '../../assets/STRIKELOGO.png';
import './navbar.css';

const Navbar = () => {
  return (
    <div className="navbar">
      <div className="navbar-links">
        <div className="navbar-links_logo">
          <Link to="/">
            <img src={logo} alt="logo" />
          </Link>
        </div>
      </div>
      <div className="navbar-sign">
        <button type="button">
          <Link to="/waitlist">Get Started</Link>
        </button>
      </div>
    </div>
  );
};

export default Navbar;