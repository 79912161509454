import { useEffect } from "react";

function Scroller() {
  useEffect(() => {
    function setScrollVar() {
      const htmlElement = document.documentElement;
      const percentOfScreenHeightScrolled = htmlElement.scrollTop / htmlElement.clientHeight;
      console.log(Math.min(percentOfScreenHeightScrolled * 100, 100));
      htmlElement.style.setProperty("--scroll", Math.min(percentOfScreenHeightScrolled * 100, 100));
    }

    // Set up scroll and resize event listeners
    window.addEventListener("scroll", setScrollVar);
    window.addEventListener("resize", setScrollVar);

    // Run the function initially to set the scroll variable on load
    setScrollVar();

    // Set up intersection observer
    const observer = new IntersectionObserver((entries) => {
      for (let i = entries.length - 1; i >= 0; i--) {
        const entry = entries[i];
        if (entry.isIntersecting) {
          document.querySelectorAll("[data-img]").forEach((img) => {
            img.classList.remove("show");
          });
          const img = document.querySelector(entry.target.dataset.imgToShow);
          img?.classList.add("show");
          break;
        }
      }
    });

    document.querySelectorAll("[data-img-to-show]").forEach((section) => {
      observer.observe(section);
    });

    // Clean up event listeners and observers on component unmount
    return () => {
      window.removeEventListener("scroll", setScrollVar);
      window.removeEventListener("resize", setScrollVar);
      observer.disconnect();
    };
  }, []);

  return null; // This component doesn't render anything
}

export default Scroller;
