import React from 'react'
import './gallery.css';

const Gallery = () => (
  <div className="gallery-wrap scale-up-center">
  <div className="gallery">
    <div className="gallery-group">
      <div className="gallery-item mag1"></div>
      <div className="gallery-item mag2"></div>
      <div className="gallery-item mag3"></div>
      <div className="gallery-item mag4"></div>
      <div className="gallery-item mag5"></div>
      <div className="gallery-item mag6"></div>
      <div className="gallery-item mag7"></div>
      <div className="gallery-item mag8"></div>
    </div>
    <div className="gallery-group">
      <div className="gallery-item mag1"></div>
      <div className="gallery-item mag2"></div>
      <div className="gallery-item mag3"></div>
      <div className="gallery-item mag4"></div>
      <div className="gallery-item mag5"></div>
      <div className="gallery-item mag6"></div>
      <div className="gallery-item mag7"></div>
      <div className="gallery-item mag8"></div>
    </div>
  </div>
</div>
);

export default Gallery;
