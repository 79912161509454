import React from 'react';
import './features.css';
import { Link } from 'react-router-dom';

const Features = () => (
  <footer className="footer section__padding scale-up-center">
    <h2>UNLOCK YOUR POTENTIAL</h2>
    <hr className="footer-divider" />
    <button type="button">
      <Link to="/waitlist">Secure your spot</Link>
    </button>
  </footer>
);

export default Features;