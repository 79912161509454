import React from 'react';
import { createRoot } from 'react-dom/client';

import App from './App';
import './index.css';
import {createBrowserRouter, RouterProvider} from 'react-router-dom'
import Waitlist from './pages/Waitlist/Waitlist';

const router = createBrowserRouter([
{
    path:'/',
    element: <App />,
},
{
    path:'waitlist',
    element: <Waitlist />,
},
])

const container = document.getElementById('root');
const root = createRoot(container);
root.render(
    <RouterProvider router={router} />    
);