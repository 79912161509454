// useScrollToTop.js
import { useEffect } from 'react';

function useScrollToTop() {
  useEffect(() => {
    const handleBeforeUnload = () => {
      window.scrollTo(0, 0);
    };

    window.addEventListener('beforeunload', handleBeforeUnload);
    window.scrollTo(0, 0); // Initial scroll to top

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []);
}

export default useScrollToTop;
