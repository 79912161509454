import React from 'react';
import './scroll.css';
import DynamicScrollSection from '../scroller/DynamicScrollSection';

const Scroll = () => {
  return (
    <div className="scroll-container">
      <DynamicScrollSection />
    </div>
  );
};

export default Scroll;