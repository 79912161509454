import React from 'react';
import { FaInstagram, FaLinkedin } from 'react-icons/fa';
import { FaXTwitter } from 'react-icons/fa6';
import './footer.css';

const Footer = () => (
  <div className="footer-text section__padding scale-up-center">
    <p>Empowering Athletes and Businesses, Unlocking Opportunities</p>
    <div className="footer-social">
      <a href="https://www.instagram.com/strikentwrk/" target="_blank" rel="noopener noreferrer">
        <FaInstagram size={34} />
      </a>
      <a href="https://www.linkedin.com/company/strike-ntwrk/posts/?feedView=all" target="_blank" rel="noopener noreferrer">
        <FaLinkedin size={34} />
      </a>
      <a href="https://x.com/StrikeNtwrk" target="_blank" rel="noopener noreferrer">
        <FaXTwitter size={34} />
      </a>
    </div>
  </div>
);

export default Footer;
