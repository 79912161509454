import React from 'react';
import './header.css';

const Header = () => (
  <header className="header scale-up-center section__padding">
        <div className="logo scale-up-center"></div>
        <h1 className="scale-up-center">STRIKE</h1>
        <h2 className="scale-up-center">LET'S STRIKE A DEAL</h2>
      </header>
);

export default Header;