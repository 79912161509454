import React, { useState } from 'react';
import './tabs.css';

const Tabs = () => {
  const [activeTab, setActiveTab] = useState(0);

  const handleTabClick = (index) => {
    setActiveTab(index);
  };

  return (
    <>
      <section className="learn section__padding scale-up-center">
        <h2>HOW IT WORKS</h2>
      </section>

      <div className="tabs-container">
        <div className="tabs" role="tablist">
          <button
            className={`tab ${activeTab === 1 ? 'active' : ''}`}
            onClick={() => handleTabClick(1)}
            role="tab"
            aria-selected={activeTab === 1}
            aria-controls="tabpanel-1"
            id="tab-1"
          >
            Athletes
          </button>
          <button
            className={`tab ${activeTab === 0 ? 'active' : ''}`}
            onClick={() => handleTabClick(0)}
            role="tab"
            aria-selected={activeTab === 0}
            aria-controls="tabpanel-0"
            id="tab-0"
          >
            Marketplace
          </button>
          <button
            className={`tab ${activeTab === 2 ? 'active' : ''}`}
            onClick={() => handleTabClick(2)}
            role="tab"
            aria-selected={activeTab === 2}
            aria-controls="tabpanel-2"
            id="tab-2"
          >
            Businesses
          </button>
        </div>
        <div className="contents">
          <div
            className={`content ${activeTab === 0 ? 'active' : ''}`}
            role="tabpanel"
            id="tabpanel-0"
            aria-labelledby="tab-0"
          >
            <div className="content-text">
              <h3>Maximize Your NIL Potential:</h3>
              <p>
                Whether in high school or college, our platform connects you with brands that align
                with your personal brand.
              </p>
              <h3>Seamless Sponsorships:</h3>
              <p>
                Get matched with sponsors that reflect your values and interests, helping you grow
                both your athletic and personal brands.
              </p>
              <h3>Easy-to-Use Tools:</h3>
              <p>
                Manage sponsorships, contracts, and payments in one place with transparent and
                user-friendly features.
              </p>
              <h3>Opportunities:</h3>
              <p>
                Currently, only 20-25% of NCAA athletes have secured NIL brand deals – we're here
                to change that and unlock new opportunities for athletes to thrive.
              </p>
              <h3>Personalized Growth:</h3>
              <p>
              Connect with brands that resonate with your unique identity, fostering meaningful partnerships that support both personal and professional growth.
              </p>
            </div>
          </div>
          <div
            className={`content ${activeTab === 1 ? 'active' : ''}`}
            role="tabpanel"
            id="tabpanel-1"
            aria-labelledby="tab-1"
          >
            <div className="content-text">
              <h3>Exclusive Access:</h3>
              <p>
                Gain early access to brand partnerships in a growing NIL market. Strike connects
                athletes and brands to create mutually beneficial partnerships.
              </p>
              <h3>Support for All Athletes:</h3>
              <p>
                Whether you're just starting or already established, we provide the tools and
                opportunities to help you thrive.
              </p>
              <h3>Tailored Analytics:</h3>
              <p>
              Leverage detailed insights to enhance your brand's reach, optimize performance, and secure larger, more impactful sponsorship deals.
              </p>
              <h3>Signing up is free:</h3>
              <p>
              Unlike competitors, we offer cost-effective NIL opportunities with no upfront fees, letting athletes keep more of their earnings.
              </p>
              <h3>Unlike the competition,</h3>
              <p>
              Built by athletes for athletes, we make NIL opportunities accessible and relatable for those on the same journey.
              </p>
            </div>
          </div>
          <div
            className={`content ${activeTab === 2 ? 'active' : ''}`}
            role="tabpanel"
            id="tabpanel-2"
            aria-labelledby="tab-2"
          >
            <div className="content-text">
              <h3>Access to Emerging Talent:</h3>
              <p>
                Our platform gives brands exclusive access to athletes across high schools and
                colleges.
              </p>
              <h3>Targeted Advertising:</h3>
              <p>
                Connect with athletes that best fit your brand's identity for effective and
                authentic partnerships.
              </p>
              <h3>Measurable Results:</h3>
              <p>
                Use real-time analytics to track the performance of your sponsored athletes and
                assess the impact of your campaigns.
              </p>
              <h3>Customizable Sponsorship Packages:</h3>
              <p>Tailor sponsorship deals to meet both brand objectives and athlete needs, ensuring mutually beneficial partnerships that maximize brand exposure.
              </p>
              <h3>Cost-Effective Sponsorships:</h3>
              <p>
              Connect with athletes affordably, aligning sponsorships with your brand's goals without the high costs of traditional advertising.
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Tabs;